import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "..//assets/img/header-img.svg";

export const Banner = () => {
    const [ loopNum, setLoopNum ] = useState( 0 );
    const [ isDeleting, setIsDeleting ] = useState( false );
    const toRotate = [ "Web Developer", "Cloud Architect", "UX Designer" ];
    const [ text, setText ] = useState( '' );
    const [ delta, setDelta ] = useState( 300 - Math.random() * 100 );
    const period = 800;

    useEffect( () => {
        let ticker = setInterval( () => {
            tick();
            //eslint-disable-next-line
        }, delta );

        return () => { clearInterval( ticker ) };
        // eslint-disable-next-line
    }, [ text ] )

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[ i ];
        let updatedText = isDeleting ? fullText.substring( 0, text.length - 1 ) : fullText.substring( 0, text.length + 1 );

        setText( updatedText );

        if ( isDeleting ) {
            setDelta( prevDelta => prevDelta / 2 )
        }

        if ( !isDeleting && updatedText === fullText ) {
            setIsDeleting( true );
            setDelta( period );
        } else if ( isDeleting && updatedText === '' ) {
            setIsDeleting( false );
            setLoopNum( loopNum + 1 );
            setDelta( 500 );
        }
    }

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={ 12 } md={ 6 } xl={ 7 }>
                        <span className="tagline">Steven Reginald Barkley's Portfolio</span>
                        <h1>{ `I'm a ` }<span className="wrap">{ text }</span></h1>
                        <p>Solutions Architect who helps organizations deliver a savvy, impactful and just vision for tomorrow. My belief is UX extends life.</p>
                        <button onClick={ () => console.log( 'connect' ) }>Let's connect <ArrowRightCircle size={ 25 } /></button>
                    </Col>
                    <Col xs={ 12 } md={ 6 } xl={ 5 }>
                        <img src={ headerImg } alt="Header Img" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}